:root {
  --white: #fff;
  --bright: #ced2d8;

  --blue: #0186a4;
  --red: #bb4126;
  --green: #00a98a;
  --orange: #ff9900;

  --primary-1: #2f455c;
  --primary-2: #34f5c5;
  --primary-3: #2f455c;
  --primary-4: #0e9cc3;
  --primary-5: #1fcbf2;

  --secondary-1: #ededea;
  --secondary-2: #5f7994;
  --secondary-3: #97a2ac;

  --table-lvl-1: #fff;
  --table-lvl-2: #E8EFFC;
  --table-lvl-3: #DEE7F8;
  --table-lvl-4: #D9E2F3;
  --table-lvl-5: #D4DDED;
  --table-lvl-6: #CED8EA;
  --table-lvl-7: #C5D1E3;

  --status-success: #00A98A;
  --status-warning: #DA9227;
  --status-error: #BB4126;
  --status-critical: #711500;
  --status-inactive: #6F6F6F;

  --square-1: #34f5c5;
  --square-2: #1fc8f5;
  --square-3: #005f79;
  --square-4: #003645;


  --hover-btns: 1px solid var(--primary-2);
  --cubic: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
