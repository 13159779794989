.glow-on-hover {
  border: none;
  outline: none;
  background: #000000;
  position: relative;
  z-index: 0;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    90deg,
    var(--white),
    var(--primary-2),
    var(--white),
    var(--primary-2),
    var(--white),
    var(--primary-2),
    var(--white)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 30s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.glow-on-hover:hover {
  color: #000;
}

.glow-on-hover:hover:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-2);
  left: 0;
  top: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}