@-moz-keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@-webkit-keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@-o-keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@-ms-keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@-khtml-keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@keyframes square-one {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  8.3333333333% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  12.5%,
  41.6666666667% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-2);
  }
  45.8333333333% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  50% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
}
@-moz-keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@-webkit-keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@-o-keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@-ms-keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@-khtml-keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@keyframes square-two {
  0%,
  8.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  12.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  16.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  20.8333333333%,
  33.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  37.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  41.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  45.8333333333%,
  58.3333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
  62.5% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  66.6666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  70.8333333333%,
  83.3333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-3);
  }
  87.5% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  91.6666666667% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  95.8333333333% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-2);
  }
}
@-moz-keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@-webkit-keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@-o-keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@-ms-keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@-khtml-keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@keyframes square-three {
  0%,
  16.6666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
  20.8333333333% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  25% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  29.1666666667%,
  75% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-4);
  }
  79.1666666667% {
    -webkit-transform: translate(100%, -100%);
    -moz-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
  }
  83.3333333333% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  87.5% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-3);
  }
}
@-moz-keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@-webkit-keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@-o-keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@-ms-keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@-khtml-keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@keyframes square-four {
  0%,
  25% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
  29.1666666667% {
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  33.3333333333% {
    -webkit-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }
  37.5%,
  66.6666666667% {
    -webkit-transform: translate(-100%, 0%);
    -moz-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%);
    background: var(--square-3);
  }
  70.8333333333% {
    -webkit-transform: translate(-100%, 100%);
    -moz-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
  }
  75% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }
  79.1666666667% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-4);
  }
}
@-moz-keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}
@-webkit-keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}
@-o-keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}
@-ms-keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}
@-khtml-keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}
@keyframes square-five {
  0% {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background: var(--square-1);
  }
  4.1666666667%,
  50% {
    -webkit-transform: translate(0%, 100%);
    -moz-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
    background: var(--square-1);
  }
  54.1666666667% {
    -webkit-transform: translate(0%, 200%);
    -moz-transform: translate(0%, 200%);
    transform: translate(0%, 200%);
  }
  58.3333333333% {
    -webkit-transform: translate(100%, 200%);
    -moz-transform: translate(100%, 200%);
    transform: translate(100%, 200%);
    background: var(--square-2);
  }
  62.5%,
  91.6666666667% {
    -webkit-transform: translate(100%, 100%);
    -moz-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    background: var(--square-2);
  }
  95.8333333333% {
    -webkit-transform: translate(100%, 0%);
    -moz-transform: translate(100%, 0%);
    transform: translate(100%, 0%);
    background: var(--square-1);
  }
}

.loader {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}
.loader .square {
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -ms-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  border: 2px solid white;
  height: 18px;
  width: 18px;
  display: inline-block;
  position: relative;
  float: left;
}
.loader .square:nth-child(1) {
  -webkit-animation-name: square-one;
  -moz-animation-name: square-one;
  animation-name: square-one;
  background: var(--square-1);
}
.loader .square:nth-child(2) {
  -webkit-animation-name: square-two;
  -moz-animation-name: square-two;
  animation-name: square-two;
  background: var(--square-2);
}
.loader .square:nth-child(3) {
  -webkit-animation-name: square-three;
  -moz-animation-name: square-three;
  animation-name: square-three;
  background: var(--square-3);
}
.loader .square:nth-child(4) {
  -webkit-animation-name: square-four;
  -moz-animation-name: square-four;
  animation-name: square-four;
  background: var(--square-4);
}
.loader .square:nth-child(5) {
  -webkit-animation-name: square-five;
  -moz-animation-name: square-five;
  animation-name: square-five;
  position: absolute;
  bottom: 100%;
  left: 0;
  background: var(--square-1);
}
